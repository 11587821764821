<template>
  <ul class="personal-choose">
    <li
      v-for="item in list"
      :key="item.label"
      :class="value === item.label ? 'active' : ''"
      @click="clickRadio(item.label)"
    >
      {{ item.title }}
      <span class="myInfo" v-if="item.title === '我的消息' && notRead > 0">{{
        notRead
      }}</span>
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      list: [
        {
          label: "/personal/perfect",
          title: "完善数据",
          // src: require("@/assets/pc/images/personal/perfect.png"),
          // active: require("@/assets/pc/images/personal/activePerfect.png"),
        },
        {
          label: "/personal/read",
          title: "已读政策",
          // src: require("@/assets/pc/images/personal/read.png"),
          // active: require("@/assets/pc/images/personal/activeRead.png"),
        },
        {
          label: "/personal/declare",
          title: "申报记录",
          // src: require("@/assets/pc/images/personal/account.png"),
          // active: require("@/assets/pc/images/personal/activeAccount.png"),
        },
        {
          label: "/personal/info",
          title: "我的消息",
          // src: require("@/assets/pc/images/personal/info.png"),
          // active: require("@/assets/pc/images/personal/activeInfo.png"),
        },
        {
          label: "/personal/account",
          title: "账号管理",
          // src: require("@/assets/pc/images/personal/account.png"),
          // active: require("@/assets/pc/images/personal/activeAccount.png"),
        },
        // {
        //   label: "/personal/activity",
        //   title: "我的活动",
        //   // src: require("@/assets/pc/images/personal/account.png"),
        //   // active: require("@/assets/pc/images/personal/activeAccount.png"),
        // },
        {
          label: "/personal/activityRecord",
          title: "活动报名记录",
        },
      ],
      notRead: localStorage.getItem("notRead"),
    };
  },
  computed: {
    ...mapState({
      readNot: (state) => state.personal.notRead,
    }),
  },
  watch: {
    readNot: {
      handler(newVal) {
        if (localStorage.getItem("notRead")) {
          this.notRead = localStorage.getItem("notRead");
        } else {
          this.notRead = newVal;
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    clickRadio(label) {
      this.$emit("input", label);
      this.$emit("change", label);
    },
  },
};
</script>

<style lang="less" scoped>
.personal-choose {
  li {
    width: 180px;
    height: 48px;
    line-height: 48px;
    padding-left: 18px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    box-sizing: border-box;
    .myInfo {
      padding: 0px 8px;
      background-color: red;
      color: #fff;
      border-radius: 8px;
      font-size: 12px;
    }
    &.active {
      background-color: #ebf3ff;
      color: #156ED0;
    }
  }
}
</style>
